export default function Header({guessCount, guessedColors, headerDisplayColor}) {
    const guessInfo = guessedColors[headerDisplayColor]

    return (
        <div className="header-box">
            <h1>Spectrle</h1>
            {guessInfo ? <div className="header-score">
                <div className="guesses">
                    Guesses: {guessCount} 
                </div>
                <div>
                    { `${headerDisplayColor}: ${Math.round(guessInfo.score * 10) / 10}` }
                </div>
            </div> : null}
        </div>
    )
}
