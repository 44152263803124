const colorsCsv = `Black,#000000,1903–present
Midnight Blue,#003366,1903–present
Navy Blue,#0066CC,1958–present
Blue (III),#0066FF,1949–present
Tropical Rain Forest,#00755E,1993–present
Teal Blue,#008080,1990–2003
Blue-Green,#0095B7,1949–present
Pacific Blue,#009DC4,1993–present
Caribbean Green,#00CC99,1997–present
Robin's Egg Blue,#00CCCC,1993–present
Pine Green,#01796F,"1903–1949, 1958–present"
Green,#01A638,1903–present
Cerulean,#02A4D3,1990–present
Denim,#1560BD,1993–present
Mountain Meadow,#1AB385,1998–present
Green-Blue,#2887C8,1958–1990
Jungle Green,#29AB87,1990–present
Outer Space,#2D383A,1998–present
Shamrock,#33CC99,1993–present
Bluetiful,#3C69E7,2017–present[5]
Indigo,#4F69C6,1999–present
Forest Green,#5FA777,1949–present
Eggplant,#614051,1998–present
Fern,#63B76C,1998–present
Blue-Violet,#6456B7,1949–present
Purple Heart,#652DC1,1998–present
Raw Umber,#665233,1903–1990
Royal Purple,#6B3FA0,1990–present
Turquoise Blue,#6CDAE7,1935–present
Violet-Blue,#766EC8,1903–circa 1910
Sky Blue,#76D7EA,1958–present
Wild Blue Yonder,#7A89B8,2003–present
Asparagus,#7BA05B,1993–present
Vivid Violet,#803790,1997–present
Violet (II),#8359A3,"1930–1949, 1958–present"
Shadow,#837050,1998–present
Fuzzy Wuzzy,#87421F,1998–present
Gray,#8B8680,1926–present
Manatee,#8D90A1,1998–present
Plum,#8E3179,1958–present
Light Blue,#8FD8D8,1958
Beaver,#926F5B,1998–present
Cornflower,#93CCEA,1958–present
Sea Green,#93DFB8,1949–present
Aquamarine,#95E0E8,1949–present
Blue Bell,#9999CC,1998–present
Granny Smith Apple,#9DE093,1993–present
Sepia,#9E5B40,"1935–1944, 1958–present"
Jazzberry Jam,#A50B5E,2003–present
Cadet Blue,#A9B2C3,1958–present
Brown,#AF593E,1903–present
Inchworm,#AFE313,2003–present
Olive Green,#B5B35C,1903–present
Chestnut,#B94E48,1903–present
Red-Violet,#BB3385,1930–present
Lavender (I),#BF8FCC,1949–1958
Powder Blue,#C0D5F0,2021
Fuchsia,#C154C1,1990–present
Maroon,#C32148,1949–present
Periwinkle,#C3CDE6,1958–present
Yellow-Green,#C5E17A,1930–present
Brick Red,#C62D42,1958–present
Mulberry,#C8509B,1958–2003
Antique Brass,#C88A65,1998–present
Blue-Gray,#C8C8CD,1958–1990
Wisteria,#C9A0DC,1993–present
Silver,#C9C0BB,1903–present
Mahogany,#CA3435,1949–present
Raw Sienna,#D27D46,1958–present
Purple Mountains' Majesty,#D6AEDD,1993–present
Thistle,#D8BFD8,1949–1999
Timberwolf,#D9D6CF,1993–present
Oatmeal,#D9DAD2,2021
Cerise,#DA3287,1993–present
Copper,#DA8A67,"1903–1915, 1958–present"
Blush,#DB5079,1998–present
Cool Mint,#DDEBEC,2021
Tumbleweed,#DEA681,1993–present
Orchid,#E29CD2,1949–present
Razzmatazz,#E30B5C,1993–present
Gold (II),#E6BE8A,1953–present
Mango Tango,#E77200,2003–present
Burnt Sienna,#E97451,1903–present
Spring Green,#ECEBBD,1958–present
Red,#ED0A3F,1903–present
Desert Sand,#EDC9AF,1998–present
Almond,#EED9C4,1998–present
Mauvelous,#F091A9,1993–present
Crayellow,#F1D651,2021
Green-Yellow,#F1E788,1958–present
Maize,#F2C649,1903–1990
Magenta,#F653A6,1903–present
Violet-Red,#F7468A,1958–present
Pink Sherbert,#F7A38E,1998–present
Orange-Yellow,#F8D568,1958–1990
Tan,#FA9D5A,1958–present
Lavender (II),#FBAED2,1958–present
Banana Mania,#FBE7B2,1998–present
Yellow,#FBE870,1903–present
Pink Flamingo,#FC74FD,1997–present
Tickle Me Pink,#FC80A5,1993–present
Goldenrod,#FCD667,1903–present
Scarlet,#FD0E35,1998–present
Apricot,#FDD5B1,1958–present
Piggy Pink,#FDD7E4,1998–present
Sunset Orange,#FE4C40,1997–present
Bittersweet,#FE6F5E,1958–present
Melon,#FEBAAD,1958–present
Dandelion,#FED85D,1990–2017[2][3][4]
Wild Strawberry,#FF3399,1990–present
Orange-Red,#FF5349,1958–1990
Red-Orange,#FF681F,1930–present
Burnt Orange,#FF7034,1958–present
Orange,#FF8833,1903–present
Salmon,#FF91A4,1949–present
Vivid Tangerine,#FF9980,1990–present
Carnation Pink,#FFA6C9,1903–present
Yellow-Orange,#FFAE42,1930–present
Cotton Candy,#FFB7D5,1998–present
Macaroni and Cheese,#FFB97B,1993–present
Peach,#FFCBA4,1903–present
Canary,#FFFF99,1998–present
Lemon Yellow,#FFFF9F,1903–1990
White,#FFFFFF,1903–present`

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    R: parseInt(result[1], 16),
    G: parseInt(result[2], 16),
    B: parseInt(result[3], 16)
  } 
}

const colors = {}
colorsCsv.split("\n").forEach((row) => {
  const rowArray = row.split(",")
  const rgbColors = hexToRgb(rowArray[1])

  colors[rowArray[0]] = rgbColors
})


export default colors
