import COLORS from './colors'

export default function GuessedColorBar({ guessedColors, headerDisplayColor, setHeaderDisplayColor }) {
  const sortedGuessedColors = Object.keys(guessedColors).sort((colorA, colorB) => { 
    return (parseFloat(guessedColors[colorA]['score']) > parseFloat(guessedColors[colorB].score) ? 1 : -1) 
  })

  return (
    <>
      <div className="color-bar-score">
        {sortedGuessedColors[0] && <span className="low-guess guess-placeholder noselect">{Math.round(guessedColors[sortedGuessedColors[0]].score * 10) / 10}</span>}
        {sortedGuessedColors.map((color) => {
          return (
            <div key={color} className="color-bar-item" style={{backgroundColor: "transparent" }}>
              {color === headerDisplayColor ? '●' : null}
            </div>
          )
        })}
        {sortedGuessedColors[0] && <span className="high-guess guess-placeholder noselect">{Math.round(guessedColors[sortedGuessedColors[sortedGuessedColors.length - 1]].score * 10) / 10}</span>}
      </div>
      <div className="color-bar">
        {sortedGuessedColors[0] && <span className="low-guess">{Math.round(guessedColors[sortedGuessedColors[0]].score * 10) / 10}</span>}
        {sortedGuessedColors.map((color) => {
          return (
            <div key={color} onClick={() => setHeaderDisplayColor(color)} className="color-bar-item clickable" style={{backgroundColor: `rgb(${COLORS[color].R}, ${COLORS[color].G}, ${COLORS[color].B})`}} />
          )
        })}
        {sortedGuessedColors[0] && <span className="high-guess">{Math.round(guessedColors[sortedGuessedColors[sortedGuessedColors.length - 1]].score * 10) / 10}</span>}
      </div>
    </>
  )
}
