//sR, sG and sB (Standard RGB) input range = 0 ÷ 255
//X, Y and Z output refer to a D65/2° standard illuminant.
import COLORS from "./colors"

//http://www.easyrgb.com/en/math.php
const rgbToXyz = ({R, G, B}) => {
  let var_R = ( R / 255.0 )
  let var_G = ( G / 255.0 )
  let var_B = ( B / 255.0 )

  if ( var_R > 0.04045 ) var_R = ( ( var_R + 0.055 ) / 1.055 ) ** 2.4
  else                   var_R = var_R / 12.92
  if ( var_G > 0.04045 ) var_G = ( ( var_G + 0.055 ) / 1.055 ) ** 2.4
  else                   var_G = var_G / 12.92
  if ( var_B > 0.04045 ) var_B = ( ( var_B + 0.055 ) / 1.055 ) ** 2.4
  else                   var_B = var_B / 12.92

  var_R = var_R * 100
  var_G = var_G * 100
  var_B = var_B * 100

  const X = var_R * 0.4124 + var_G * 0.3576 + var_B * 0.1805
  const Y = var_R * 0.2126 + var_G * 0.7152 + var_B * 0.0722
  const Z = var_R * 0.0193 + var_G * 0.1192 + var_B * 0.9505

  return { X, Y, Z }
}

const refX = 94.811
const refY = 100.000
const refZ = 107.304

const xyzToCie = ({X, Y, Z}) => {
  //Reference-X, Y and Z refer to specific illuminants and observers.
//Common reference values are available below in this same page.
  let var_X = X / refX
  let var_Y = Y / refY
  let var_Z = Z / refZ

  if ( var_X > 0.008856 ) var_X = var_X ** ( 1.0/3 )
  else                    var_X = ( 7.787 * var_X ) + ( 16.0 / 116 )
  if ( var_Y > 0.008856 ) var_Y = var_Y ** ( 1.0/3 )
  else                    var_Y = ( 7.787 * var_Y ) + ( 16.0 / 116 )
  if ( var_Z > 0.008856 ) var_Z = var_Z ** ( 1.0/3 )
  else                    var_Z = ( 7.787 * var_Z ) + ( 16.0 / 116 )

  const CIEl = ( 116 * var_Y ) - 16
  const CIEa = 500 * ( var_X - var_Y )
  const CIEb = 200 * ( var_Y - var_Z )

  return { CIEl, CIEa, CIEb }
}


const rgbToCie = ({ R, G, B }) => {
  return xyzToCie(rgbToXyz({ R, G, B}))
}

const WHTL = 1
const WHTC = 1
const WHTH = 1

// export const calculateScoreCie = ({ targetRGB, guessRGB }) => {
//   const c = COLORS;
//   let targetCIE = rgbToCie(targetRGB)
//   let guessCIE = rgbToCie(guessRGB)

//   const CIEa1 = targetCIE.CIEa
//   const CIEb1 = targetCIE.CIEb
//   const CIEl1 = targetCIE.CIEl
//   const CIEa2 = guessCIE.CIEa
//   const CIEb2 = guessCIE.CIEb
//   const CIEl2 = guessCIE.CIEl
//   debugger
//   // CIEl1, CIEa1, CIEb1          //Color #1 CIE-L*ab values
//   // CIEl2, CIEa2, CIEb2          //Color #2 CIE-L*ab values
//   // WHT-L, WHT-C, WHT-H                //Weighting factors

//   let xC1 = ( ( CIEa1 ** 2 ) + ( CIEb1 ** 2 ) ) ** 0.5
//   let xC2 = ( ( CIEa2 ** 2 ) + ( CIEb2 ** 2 ) ) ** 0.5
//   let xDL = CIEl2 - CIEl1
//   let xDC = xC2 - xC1
//   let xDE = ( ( ( CIEl1 - CIEl2 ) * ( CIEl1 - CIEl2 ) )
//             + ( ( CIEa1 - CIEa2 ) * ( CIEa1 - CIEa2 ) )
//             + ( ( CIEb1 - CIEb2 ) * ( CIEb1 - CIEb2 ) ) ) ** 0.5

//   let xDH = ( xDE * xDE ) - ( xDL * xDL ) - ( xDC * xDC )
//   if ( xDH > 0 )
//   {
//     xDH = ( xDH ) ** 0.5
//   }
//   else
//   {
//     xDH = 0
//   }
//   let xSC = 1 + ( 0.045 * xC1 )
//   let xSH = 1 + ( 0.015 * xC1 )
//   xDL = xDL / WHTL
//   xDC = xDC / WHTC * xSC
//   xDH = xDH / WHTH * xSH

//   debugger
//   return 100 - (( xDL ** 2 + xDC ** 2 + xDH ** 2 ) ** 0.5)
// }

export const calculateScoreCie = ({ targetRGB, guessRGB }) => {
  // Utility functions added to Math Object
  let targetCIE = rgbToCie(targetRGB)
  let guessCIE = rgbToCie(guessRGB)

  const a1 = targetCIE.CIEa
  const b1 = targetCIE.CIEb
  const l1 = targetCIE.CIEl
  const a2 = guessCIE.CIEa
  const b2 = guessCIE.CIEb
  const l2 = guessCIE.CIEl

  Math.rad2deg = function(rad) {
    return 360 * rad / (2 * Math.PI);
  };
  Math.deg2rad = function(deg) {
    return (2 * Math.PI * deg) / 360;
  };
  // Start Equation
  // Equation exist on the following URL http://www.brucelindbloom.com/index.html?Eqn_DeltaE_CIE2000.html
  const avgL = (l1 + l2) / 2;
  const c1 = Math.sqrt(Math.pow(a1, 2) + Math.pow(b1, 2));
  const c2 = Math.sqrt(Math.pow(a2, 2) + Math.pow(b2, 2));
  const avgC = (c1 + c2) / 2;
  const g = (1 - Math.sqrt(Math.pow(avgC, 7) / (Math.pow(avgC, 7) + Math.pow(25, 7)))) / 2;

  const a1p = a1 * (1 + g);
  const a2p = a2 * (1 + g);

  const c1p = Math.sqrt(Math.pow(a1p, 2) + Math.pow(b1, 2));
  const c2p = Math.sqrt(Math.pow(a2p, 2) + Math.pow(b2, 2));

  const avgCp = (c1p + c2p) / 2;

  let h1p = Math.rad2deg(Math.atan2(b1, a1p));
  if (h1p < 0) {
    h1p = h1p + 360;
  }

  let h2p = Math.rad2deg(Math.atan2(b2, a2p));
  if (h2p < 0) {
    h2p = h2p + 360;
  }

  const avghp = Math.abs(h1p - h2p) > 180 ? (h1p + h2p + 360) / 2 : (h1p + h2p) / 2;

  const t = 1 - 0.17 * Math.cos(Math.deg2rad(avghp - 30)) + 0.24 * Math.cos(Math.deg2rad(2 * avghp)) + 0.32 * Math.cos(Math.deg2rad(3 * avghp + 6)) - 0.2 * Math.cos(Math.deg2rad(4 * avghp - 63));

  let deltahp = h2p - h1p;
  if (Math.abs(deltahp) > 180) {
    if (h2p <= h1p) {
      deltahp += 360;
    } else {
      deltahp -= 360;
    }
  }

  const deltalp = l2 - l1;
  const deltacp = c2p - c1p;

  deltahp = 2 * Math.sqrt(c1p * c2p) * Math.sin(Math.deg2rad(deltahp) / 2);

  const sl = 1 + ((0.015 * Math.pow(avgL - 50, 2)) / Math.sqrt(20 + Math.pow(avgL - 50, 2)));
  const sc = 1 + 0.045 * avgCp;
  const sh = 1 + 0.015 * avgCp * t;

  const deltaro = 30 * Math.exp(-(Math.pow((avghp - 275) / 25, 2)));
  const rc = 2 * Math.sqrt(Math.pow(avgCp, 7) / (Math.pow(avgCp, 7) + Math.pow(25, 7)));
  const rt = -rc * Math.sin(2 * Math.deg2rad(deltaro));

  const kl = 1;
  const kc = 1;
  const kh = 1;

  const deltaE = Math.sqrt(Math.pow(deltalp / (kl * sl), 2) + Math.pow(deltacp / (kc * sc), 2) + Math.pow(deltahp / (kh * sh), 2) + rt * (deltacp / (kc * sc)) * (deltahp / (kh * sh)));

  const adjustedDeltaE = ((100 - deltaE) ** 1.8) / (100 ** 1.8 / 100)
  return adjustedDeltaE;
}

// calculateScoreCie({ guessRGB: {R: 198, G: 45, B: 66}, targetRGB: {R: 236, G: 235, B: 189} })

// rgbToCie({R: 198, G: 45, B: 66})

// rgbToCie({R: 236, G: 235, B: 189})
