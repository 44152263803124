import logo from './logo.svg';
import './App.css';
import ColorPicker from './ColorPicker'
import Header from './Header'
import React, { useState } from 'react';
import COLORS from './colors'
import GuessedColorBar from './GuessedColorBar'
import { calculateScoreCie } from './score'

const calculateScore = (target, guess) => {
  // const rDiff = (COLORS[target].R - COLORS[guess].R) ** 2
  // const bDiff = (COLORS[target].B - COLORS[guess].B) ** 2
  // const gDiff = (COLORS[target].G - COLORS[guess].G) ** 2

  // const rootOfDiffs = (((rDiff + bDiff + gDiff) / 3) ** 0.5)

  // return (100 - (rootOfDiffs / 2.55)).toFixed(2)

  return calculateScoreCie(
    {
      targetRGB: COLORS[target],
      guessRGB: COLORS[guess],
    }
  )
}

const pickRandomColor = () => {
  const random = Math.floor(Math.random() * Object.keys(COLORS).length)

  return Object.keys(COLORS)[random]
}

function App() {
  const [guessCount, setGuessCount] = useState(0)
  const [score, setScore] = useState(null)
  const [guessedColors, setGuessedColors] = useState({})
  const [headerDisplayColor, setHeaderDisplayColor] = useState(null)

  const [targetColor, setTargetColor] = useState(pickRandomColor())
  console.log(targetColor)

  const startNewGame = () => {
    setGuessCount(0)
    setScore(null)
    setTargetColor(pickRandomColor())
    setGuessedColors({})
  }


  const onGuess = (guessColor) => {
    const score = calculateScore(targetColor, guessColor)
    setHeaderDisplayColor(guessColor)
    if (guessColor == targetColor) {
      window.alert(`Congrats! You won the color was ${targetColor}! Play again?`)
      setScore(score)
      setGuessedColors({...guessedColors, [guessColor]: { score: score, guessNumber: guessCount + 1 }})
      // startNewGame()
    } else {
      setScore(score)
      setGuessedColors({...guessedColors, [guessColor]: { score: score, guessNumber: guessCount + 1 }})
    }

    setGuessCount(guessCount + 1)
  }
  
  return (
    <div className="App">
      <div className="fixed">
        <Header guessCount={guessCount} guessedColors={guessedColors} headerDisplayColor={headerDisplayColor} />
        <GuessedColorBar guessedColors={guessedColors} headerDisplayColor={headerDisplayColor} setHeaderDisplayColor={setHeaderDisplayColor} />
      </div>
      <div className="non-content">  
        <ColorPicker onGuess={onGuess} guessedColors={guessedColors} setHeaderDisplayColor={setHeaderDisplayColor} />
      </div>
    </div>
  );
}

export default App;
