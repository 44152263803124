import COLORS from './colors'

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}
const shuffledColors = shuffle(Object.keys(COLORS))

export default function ColorPicker({ onGuess, guessedColors, setHeaderDisplayColor }) {
    return (
        <div className="container">
            <div className="picker-container">
                {shuffledColors.map((key) => {
                    return (
                        <div key={key} 
                            onClick={() => guessedColors[key] ? setHeaderDisplayColor(key) : onGuess(key)} 
                            className="color-item" 
                            style={{ 
                                cursor: 'pointer',
                                backgroundColor: guessedColors[key] ? `rgb(${COLORS[key].R}, ${COLORS[key].G}, ${COLORS[key].B})` : `rgb(240, 240, 240)`
                            }
                        }>
                            <div>{key}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
